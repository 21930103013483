// PrintController - print the window
//
// @example
//  <div data-controller="print">
//    <button data-action="click->print#printWindow">
//      Print
//    </button>
//  </div>

import {Controller} from "stimulus";

export default class extends Controller {
  printWindow(_event) {
    window.print();
  }
}
