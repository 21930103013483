import {Controller} from "stimulus";

export default class ClaimsController extends Controller<HTMLFormElement> {
  static targets = ["email", "error", "results", "submit"];
  static values = {
    loginPath: {type: String, default: "/session/new"},
    loading: {type: String, default: "Loading..."},
    submit: {type: String, default: "Next"},
  };

  declare emailTarget: HTMLInputElement;
  declare errorTarget: HTMLElement;
  declare resultsTarget: HTMLElement;
  declare submitTarget: HTMLButtonElement;

  declare loginPathValue: string;
  declare loadingValue: string;
  declare submitValue: string;

  handleSubmit(event) {
    event.preventDefault();

    const email = this.emailTarget.value;

    if (!email) return;

    this.submitTarget.disabled = true;
    this.submitTarget.textContent = this.loadingValue;

    return fetch("/api/v1/claims", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({email}),
    })
      .then((response) => response.json())
      .then((results) => {
        if (results.length === 0) {
          return this.showError();
        }

        if (results.length === 1) {
          return window.location.assign(this.buildLoginUrl(results[0].base_url, email));
        }

        this.emailTarget.disabled = true;
        this.errorTarget.classList.add("hidden");
        this.resultsTarget.classList.remove("hidden");
        this.submitTarget.style.display = "none";

        results.forEach((result) => {
          const loginUrl = this.buildLoginUrl(result.base_url, email);
          const element = this.templateElement?.cloneNode(true) as HTMLAnchorElement;

          element.href = loginUrl;

          if (element.querySelector("strong")) {
            const label = element.querySelector("strong") as HTMLElement;
            label.innerText = result.name;
          }

          if (result.logo_url && element.querySelector("img")) {
            const img = element.querySelector("img") as HTMLImageElement;
            img.src = result.logo_url;
          }

          this.resultsTarget.append(element);
        });
      })
      .catch((error) => {
        this.showError();
        console.log(error);
      });
  }

  buildLoginUrl = (base: string, email: string) => {
    return `${base}${this.loginPathValue}?email=${encodeURIComponent(email)}`;
  };

  showError = () => {
    this.submitTarget.removeAttribute("disabled");
    this.submitTarget.innerText = this.submitValue;
    this.resultsTarget.classList.add("hidden");
    this.errorTarget.classList.remove("hidden");
  };

  get templateElement() {
    return this.element.querySelector("template")?.content.firstElementChild;
  }
}
