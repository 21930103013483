import {Controller} from "stimulus";
import SignaturePad from "signature_pad";

const options = {
  throttle: 0,
  minDistance: 0,
};

export default class extends Controller {
  static targets = ["canvas", "input"];

  declare hasInputTarget: boolean;

  declare canvasTarget: HTMLCanvasElement;
  declare inputTarget: HTMLInputElement;
  declare signaturePadValue: SignaturePad;

  connect() {
    this.signaturePadValue = new SignaturePad(this.canvasTarget, options);
    this.signaturePadValue.addEventListener("endStroke", this.handleUpdateFile);
    window.addEventListener("resize", this.resizeCanvas);
    this.resizeCanvas();
    this.inputTarget.addEventListener("click", (evt) => evt.preventDefault());
  }

  disconnect() {
    this.signaturePadValue.off();
    window.removeEventListener("resize", this.resizeCanvas);
  }

  clear() {
    this.signaturePadValue.clear();
    this.handleUpdateFile();
  }

  undo() {
    const data = this.signaturePadValue.toData();

    if (data) {
      data.pop(); // remove the last edit
      this.signaturePadValue.fromData(data);
    }
    this.handleUpdateFile();
  }

  // https://github.com/szimek/signature_pad#handling-high-dpi-screens
  resizeCanvas = () => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;
    this.canvasTarget.getContext("2d")?.scale(ratio, ratio);
    this.signaturePadValue.clear();
  };

  handleUpdateFile = () => {
    if (!this.hasInputTarget) return false;

    this.canvasTarget.toBlob((blob: Blob) => {
      const file = new File([blob], "signature.png", {type: "image/png"});
      const dataTransfer = new DataTransfer();

      dataTransfer.items.add(file);
      this.inputTarget.files = dataTransfer.files;
    }, "image/png");
  };
}
