import {Application} from "stimulus";
const application = Application.start();

import ClaimsController from "src/controllers/claims_controller";
application.register("claims", ClaimsController);

import SignatureController from "src/controllers/signature_controller";
application.register("signature", SignatureController);

import PrintController from "src/controllers/print_controller";
application.register("print", PrintController);

import PDFController from "src/controllers/pdf_controller";
application.register("pdf", PDFController);
