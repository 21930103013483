// PdfController - export element/document to PDF
//
// @example
//  <div data-controller="pdf">
//    <button data-action="click->pdf#export">
//      Export to PDF
//    </button>
//  </div>

import {Controller} from "stimulus";

declare const html2pdf;

export default class PDFController extends Controller<HTMLElement> {
  static values = {
    document: {type: Boolean, default: false},
    title: {type: String, default: "export"},
  };

  declare documentValue: boolean;
  declare titleValue: string;

  connect() {
    const script = document.createElement("script");
    script.type = "application/javascript";
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js";
    script.integrity = "sha512-GsLlZN/3F2ErC5ifS5QtgpiJtWd43JWSuIgh7mbzZ8zBps+dvLusV+eNQATqgA/HdeKFVgA5v3S/cIrLF7QnIg==";
    script.crossOrigin = "anonymous";
    script.referrerPolicy = "no-referrer";
    document.head.appendChild(script);
    window.addEventListener("beforeprint", this.handlePrintStyles);
    window.addEventListener("afterprint", this.handlePrintStyles);
  }

  disconnect() {
    document.head.querySelector("script[src*='html2pdf.js']")?.remove();
    window.removeEventListener("beforeprint", this.handlePrintStyles);
    window.removeEventListener("afterprint", this.handlePrintStyles);
  }

  export() {
    const elementToCapture = this.documentValue ? document.body : this.element;
    const options = {
      margin: this.documentValue ? 0 : 10,
      filename: [this.titleValue, "pdf"].join("."),
    };

    Promise.resolve()
      .then(() => window.dispatchEvent(new Event("beforeprint")))
      .then(() => html2pdf(elementToCapture, options))
      .then(() => window.dispatchEvent(new Event("afterprint")));
  }

  handlePrintStyles = (event) => {
    const parentElement = this.documentValue ? document.documentElement : this.element.parentElement;

    if (!parentElement) return;
    // Note that these styles are dynamic, and will remove the 'print:' prefix, which may be unexpected, and may lead to classes being added that Tailwind does not recognise.

    parentElement.querySelectorAll("[class*='print:']").forEach((element) => {
      const classNames = Array.from(element.classList)
        .filter((className) => className.indexOf("print:") === 0)
        .map((className) => className.replace("print:", ""));

      if (event.type === "beforeprint") {
        classNames.map((className) => element.classList.add(className));
      } else {
        classNames.map((className) => element.classList.remove(className));
      }
    });
  };
}
